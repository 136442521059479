import * as React from "react";
import { useEffect } from "react";
import { Route, Switch } from "wouter";

import body from "./body.mdx";

const ExternalRedirect = ({ redirectTo }: { redirectTo: string }) => {
  useEffect(() => {
    window.location.href = redirectTo;
  }, [redirectTo]);

  return null;
};

function App() {
  return (
    <>
      <Switch>
        <Route path={`/privacy`}>
          <ExternalRedirect redirectTo="https://improbable.io/privacy-policy" />
        </Route>
        <Route path={`/terms`}>{body}</Route>
        <Route path={`/*`}>
          <ExternalRedirect redirectTo={window.serverConfig.DASHBOARD_URL} />
        </Route>
      </Switch>
    </>
  );
}

export default App;

import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = {
    h1: "h1",
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      children: _jsx(_components.strong, {
        children: "Terms of Service and Licence Agreement for MServe"
      })
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "LAST UPDATED:"
      }), " 28 June 2024"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "1. SCOPE"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["1.1. ", _jsx(_components.strong, {
        children: "Agreement."
      }), " This terms of service and licence agreement for MServe (“Agreement”) sets out the terms governing your use of the Software and Services. By accessing the Software and Services, you agree to the terms of this Agreement. You agree that you are at least 18 years old. The Agreement is a legally binding contract between you, either individually or, if applicable, on behalf of your corporate entity/employer (“you” or “Developer”) and Improbable MV Limited (incorporated in England) with company number 13856337 whose registered office is at 10 Bishops Square, London, E1 6EG, UK (“us”, “we”, “M2” or “MSquared”)."]
    }), "\n", _jsxs(_components.p, {
      children: ["1.2. ", _jsx(_components.strong, {
        children: "Purpose."
      }), " The purpose of this Agreement is for you to develop and test your own software products using the Software and Services, in accordance with M2’s rules, guidelines, policies and requirements and together with any other purposes or uses which the parties may agree in writing (the “Purpose”)."]
    }), "\n", _jsxs(_components.p, {
      children: ["1.3. ", _jsx(_components.strong, {
        children: "Right to Modify Terms."
      }), " We have the right to modify the terms of this Agreement (in whole or in part) from time to time without liability to you. Where we modify the terms of this Agreement we will notify you of the update on the Website. Your continued use of the Software and Services following such notification shall be deemed to be your acceptance of such revised Agreement terms."]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "2. DEFINITIONS"
      })
    }), "\n", _jsx(_components.p, {
      children: "In this Agreement, the defined terms will be as follows unless otherwise defined below:"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "“Acceptable Use Policy”"
      }), " means the document set out below (as may be amended by us from time to time)."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "“Developer Original Content”"
      }), " means any and all content, products and services with all Intellectual Property Rights in the same created by you using the M2 Property (e.g. you will be the owner of your own original ideas, concepts and/or designs for software created using M2 Property)."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "“Developer Property”"
      }), " means the Developer Original Content and all of your materials and assets that are pre-existing or were otherwise created by, or on behalf of you, without using the M2 Property."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "“M2 Property”"
      }), " means the Software and Services, the Website and all related documentation, assets and materials with all Intellectual Property Rights in them including any improvements, updates, subsequent versions, modifications, amendments and alterations to any of the aforesaid made: (i) by or on behalf of M2; or (ii) made by the Developer."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "\"Intellectual Property Rights\""
      }), " or ", _jsx(_components.strong, {
        children: "“IPRs”"
      }), " means any and all copyright, trade marks, service marks, trade dress, brand names, logos, goodwill, get up, trade, business or domain names, design rights, database rights, patents, rights in inventions, know-how, trade secrets and confidential information, rights in databases, rights in computer software, moral rights, publicity rights, performance rights, synchronisation rights, mechanical rights, publishing, rental, lending and transmission rights and other intellectual property and exploitation rights of a similar or corresponding character which may now or in the future subsist in any part of the world, in all cases whether or not registered or registrable including all granted applications and all applications for registration, division, continuation, reissuance, renewals, extensions, restorations and reversions regarding any of the same."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "“Servers”"
      }), " means servers which may be operated by or on behalf of M2 to which you may upload Developer Original Content and/or Developer Property using the Software and Services under this Agreement."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "“Software and Services”"
      }), " means MServe, the Servers (if any), maintenance and support services (as detailed in Section 3.4), and all other products and services provided by M2 to you under this Agreement."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "“MServe”"
      }), " means M2’s hosting service for Metaverse Markup Language (MML) documents, web-based virtual worlds, and associated functionality, including any and all patches, updates, fixes, new or alternate versions and all assets, files, manuals, information and other materials provided in connection with it."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "“Third Party Content”"
      }), " means any third party content, game engines, code, assets, data, materials, or information."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "“Website”"
      }), " means https://mserve.io/ and any other website owned or operated by M2 for the Software and Services (as updated and/or replaced by M2 from time to time)."]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "3. THE SOFTWARE AND SERVICES"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["3.1. ", _jsx(_components.strong, {
        children: "Access."
      }), " In order to access the Software and Services you need to create an account with us or otherwise link a third party account to the Software and Services. You agree that you will take all steps necessary to protect your log in details and keep them confidential. Your account is personal to you and you are not entitled to share your account or transfer it to any other person."]
    }), "\n", _jsx(_components.p, {
      children: "You confirm that all the information you provide to us on accessing and/or using our Services is and shall remain true, accurate and complete at all times. We will not be responsible to you for any loss or harm that you suffer as a result of an unauthorised person accessing your account and/or using our Services, whether fraudulently or otherwise."
    }), "\n", _jsx(_components.p, {
      children: "You understand that if you delete your account, or if we delete your account in accordance with these terms, you may lose access to any data previously associated with your account. You agree that you shall have no ownership or property interest in any account you create using our Services. We may suspend, terminate, modify or delete any accounts at any time for any reason or no reason, with or without notice to you."
    }), "\n", _jsx(_components.p, {
      children: "Your use of the Software and Services may be subject to rate limits or usage caps that are detailed on the Website. We reserve the right to suspend or terminate your account and/or access to the Software and Services at any time, without notice to you, in the event that you exceed such limits or caps."
    }), "\n", _jsx(_components.p, {
      children: "In these terms, references to “log in details” or “account” include your log in details and account for any social network or platform that you may allow our Services to interact with."
    }), "\n", _jsxs(_components.p, {
      children: ["3.2. ", _jsx(_components.strong, {
        children: "Fees."
      }), " We reserve the right to impose fees or other charges for access to, and use of, the Software and Services at any time on reasonable notice to you. The details of such fees or charges may be advertised publicly on the Website or will otherwise be agreed with you separately in writing (including via email)."]
    }), "\n", _jsx(_components.p, {
      children: "All amounts due or payable to us in connection with your use of the Software and Services shall be paid by you in United States Dollars either: (i) directly via the Website, without issuance of an invoice by us; or (ii) within fifteen (15) days of the date of an invoice issued by us to you. Interest shall accrue on all amounts not paid at a rate, calculated upon the unpaid balance, at the rate of three (3) per cent per annum above the base rate for the time being of Barclays Bank plc. All payments made by you to us will be sent by wire transfer to the account of our choosing as will be separately indicated to you."
    }), "\n", _jsxs(_components.p, {
      children: ["3.3. ", _jsx(_components.strong, {
        children: "Development status."
      }), " The Software and Services are still in development. Therefore, there may be missing or incomplete features, bugs or errors which may be subject to further testing, development, patches and/or updates in M2’s sole discretion. M2 does not make any promises, warranties or representations of any kind about (or accept any liability for) the Software and Services, what they do, how they do it, or about future content. The Software and Services are provided by M2 as is and without warranty or representation, express, implied or statutory, including without limitation warranty as to satisfactory purpose, merchantability, fitness for any particular purpose or availability for use; nor are there any warranties created by course of dealing or course, performance or trade usage. All implied and/or statutory representations, conditions or warranties are excluded to the extent permissible by law."]
    }), "\n", _jsxs(_components.p, {
      children: ["3.4. ", _jsx(_components.strong, {
        children: "Availability/Downtime."
      }), " There may also be times when the Software and Services (or any part of them) are not available for technical or maintenance related reasons, whether on a scheduled or unscheduled basis. Where possible we will try to give notice in advance of any planned downtime via the Website."]
    }), "\n", _jsxs(_components.p, {
      children: ["3.5. ", _jsx(_components.strong, {
        children: "Support."
      }), " We may, but are not obliged to, provide maintenance and support for the Software and Services. Should you require support in using the Software and Services we recommend that you use our community forums and comply with any applicable guidelines, which are available via the Website."]
    }), "\n", _jsxs(_components.p, {
      children: ["3.6. ", _jsx(_components.strong, {
        children: "Error Reporting."
      }), " If you need to report any error or defect in the Software and Services or any of the M2 Property, please contact us using the feedback form available on the Website."]
    }), "\n", _jsxs(_components.p, {
      children: ["3.7. ", _jsx(_components.strong, {
        children: "User Content."
      }), " Our Software and Services may include information and materials uploaded by other users of the Software and the Services, including to bulletin boards and chat rooms. This information and these materials have not been verified or approved by us. The views expressed by other users on our site do not represent our views or values."]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "4. LICENCES"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["4.1. ", _jsx(_components.strong, {
        children: "Use of the Software and Services."
      }), " During the term of this Agreement you will have access to the Software and Services, subject to any requirements from M2, including with regards to software functionality, any notified commercial/pricing requirements and available Server deployment size as further detailed via the Website. In order to provide you with the Software and Services, you grant us access to the Developer Original Content for this purpose."]
    }), "\n", _jsxs(_components.p, {
      children: ["4.2. ", _jsx(_components.strong, {
        children: "Licence to you."
      }), " All rights granted to you under this Agreement are granted by the licence below only and not by sale, and all of those rights are limited by and subject to the terms of this Agreement. No licence or other right in any M2 technology or intellectual property rights other than the Software and Services is granted under this Agreement, and no licence or other rights will be created under this Agreement by implication, estoppel, or otherwise. Any attempted sublicence that is not consistent with the terms of this Agreement will be null and void. Subject to your compliance with the terms of this Agreement, M2 grants you a personal, revocable, non-transferable, non-sublicensable and non-exclusive licence to use the Software and Services during the term of this Agreement solely and exclusively for the Purpose. You and, if applicable, your officers, employees, agents and other personnel will not at any time directly or indirectly use the Software and Services in any way that, or do or omit to do anything regarding the Software and Services, which breaches the Restrictions set out in clause 10 below, this Agreement or any other terms set out by M2 in writing from time to time."]
    }), "\n", _jsxs(_components.p, {
      children: ["4.3. ", _jsx(_components.strong, {
        children: "Licence to us."
      }), " You grant M2 a perpetual, irrevocable, royalty-free, worldwide licence to: (i) access, collect, store, process, transmit, copy and use any data and information collected by M2 or provided by you in connection with your use of the Software and Services in order to provide, develop, optimise and improve the Software and Services and any other products or services offered by M2; and (ii) where applicable, to use the Developer Original Content and/or Developer brands and the Developer Original Content developed using the Software and Services for the purpose of promoting the Developer Original Content, the Software and Services, and M2, and for the purpose of operating and improving the Software and Services."]
    }), "\n", _jsxs(_components.p, {
      children: ["4.4. ", _jsx(_components.strong, {
        children: "Security."
      }), " You will at all times ensure that the Software and Services and any confidential information disclosed by M2 to you are kept secure and that you will use all reasonable security practices and systems applicable to prevent and take prompt and proper remedial action against unauthorised access, copying, modification, storage, reproduction, display or distribution of the same."]
    }), "\n", _jsxs(_components.p, {
      children: ["4.5 ", _jsx(_components.strong, {
        children: "Open Source Software."
      }), " The Software and Services may include third-party open-source components governed by their own licenses. By using the Software and Services, you agree to comply with these licenses. These components are provided \"as is\" without any warranties or guarantees. We are not liable for any damages or losses related to these third-party components."]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "5. REGULATION"
      })
    }), "\n", _jsx(_components.p, {
      children: "Regarding your products and services made available via the Software and Services, you are solely and exclusively responsible for: (i) all legal and regulatory compliance; and (ii) any end user(s) and/or consumer matters, and you acknowledge that M2 has no responsibilities, obligations or liabilities whatsoever regarding the same. This includes (but is not limited to) applicable consumer protection, data protection and other regulatory matters. In relation to data protection matters, you will be the data controller and M2 will be the data processor. You will be responsible for the use of and entry into any applicable end user licence, privacy policy, acceptable use policy and other applicable documentation (“Terms of Service”) between you and end users and/or consumers. If you commercially use the Developer Original Content, you will ensure that the provisions of any such Terms of Service will be at a minimum of a standard equivalent to M2’s equivalent Terms of Service."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "6. TERM"
      })
    }), "\n", _jsx(_components.p, {
      children: "The Agreement will run from the date M2 grants you access to the Software and Services under this Agreement and will continue until terminated in accordance with the terms of this Agreement."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "7. SUSPENSION / TERMINATION"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["7.1. ", _jsx(_components.strong, {
        children: "By M2."
      }), " Unless the parties have agreed otherwise in writing, the Agreement may be terminated immediately on written notice by M2 at any time."]
    }), "\n", _jsxs(_components.p, {
      children: ["7.2. ", _jsx(_components.strong, {
        children: "For Inactivity."
      }), " M2 also retains the right to terminate any deployments in our sole discretion where a deployment has been inactive (as determined by us) or your use of such deployment(s) may affect our ability to provide the Software and Services to yourself or our other customers."]
    }), "\n", _jsxs(_components.p, {
      children: ["7.3. ", _jsx(_components.strong, {
        children: "For Breach."
      }), " M2 may suspend or terminate: (i) Your use of the Software and Services; or (ii) any deployments, in each case in our sole discretion where we believe that You have breached any terms in this Agreement or the Acceptable Use Policy."]
    }), "\n", _jsxs(_components.p, {
      children: ["7.3. ", _jsx(_components.strong, {
        children: "By You."
      }), " You may terminate this Agreement at any time by permanently ceasing all use of the Software and Services."]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "8. CONSEQUENCES OF TERMINATION"
      })
    }), "\n", _jsx(_components.p, {
      children: "All rights and obligations of the parties will cease to have effect immediately upon termination or expiry of this Agreement for any reason except for: (i) any and all accrued rights and obligations of the parties at the termination date; and (ii) those rights and obligations of the parties necessary for the interpretation and enforcement of it."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "9. INTELLECTUAL PROPERTY RIGHTS"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["9.1. ", _jsx(_components.strong, {
        children: "M2 IPRs."
      }), " As between the parties, M2 is the sole and exclusive owner of the M2 Property. Notwithstanding the restrictions set out in clause 10 (Restrictions) below, to the extent you make any modifications to, or any derivative works from, any of the M2 Property, you hereby assign to us all right, title and interest in and to all such modifications and derivative works and you agree that you will do all such things and take all such actions as we reasonably require in order to transfer such modifications and derivative works, and the Intellectual Property Rights in them, to us."]
    }), "\n", _jsxs(_components.p, {
      children: ["9.2. ", _jsx(_components.strong, {
        children: "Developer IPRs."
      }), " You will be the sole and exclusive owner of the Developer Property."]
    }), "\n", _jsxs(_components.p, {
      children: ["9.3. ", _jsx(_components.strong, {
        children: "Third Party Content."
      }), " To the extent that you include any Third Party Content in any of your Developer Property, you, and not us, shall be responsible for obtaining all necessary licences, consents, permissions and approvals required for your use of such content. You agree at all times that you shall not use or include any Third Party Content in any way which breaches the Acceptable Use Policy."]
    }), "\n", _jsx(_components.p, {
      children: "9.4. If the Software and Services incorporate and are bundled with code developed by third parties (“Third Party Software”) that may be subject to additional or alternative licence terms. Those terms or other attribution requirements can be found in the software documentation (to the extent required). By entering into this Agreement and using Third Party Software, you are accepting the terms of those additional or alternative licenses. In the case of additional license terms, the additional license terms will take precedence over any inconsistencies with the terms of this License with regard to the Third Party Software licensed under those additional license terms. You agree that the owners of the Third Party Software are intended third party beneficiaries to this Agreement in relation to your uses of Third Party Software."
    }), "\n", _jsxs(_components.p, {
      children: ["9.5. ", _jsx(_components.strong, {
        children: "Feedback."
      }), " This clause applies in respect of the M2 Property only; nothing in this clause is intended to give us any rights (including any Intellectual Property Rights) in any of your Developer Property. We shall own all rights (including all Intellectual Property Rights) in all oral and written feedback that you provide to us in connection with your use of the M2 Property. If requested by us and at our expense, you agree to execute all documents and provide us with all assistance that we may reasonably require from you in order to: (i) vest such rights in us; and/or (ii) register such rights in our name or the name of one of our affiliates; and/or (iii) assist us against any allegation of infringement by a third party. If you don’t wish to transfer ownership of your feedback to us then please do not provide your feedback to us."]
    }), "\n", _jsxs(_components.p, {
      children: ["9.6. ", _jsx(_components.strong, {
        children: "Independent Development."
      }), " Nothing in this Agreement will impair our, or our group companies’ or affiliates’ or partners’ right to develop, acquire, licence, market, promote or distribute games, products or technologies that perform the same or similar functions as, or otherwise compete with, any games, products or technologies you may create, whether using our Software and Services or not."]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "10. RESTRICTIONS"
      })
    }), "\n", _jsx(_components.p, {
      children: "In order to use the Software and Services, you must (unless M2 agrees otherwise in writing): (i) not copy the Software and Services (no back-up, archival or storage copies are permitted); (ii) not upload the Software and Services to the internet, servers or to any cloud based system other than the Servers and as authorised by M2; (iii) not modify, merge, distribute, translate, reverse engineer, decompile, disassemble, create derivative works of, hack or interfere with the Software and Services or any part of it; (iv) only use SDKs and GDKs provided by or authorised by M2 with the Software and Services; and (v) not use the Software and Services, or upload content to the Servers, in any way which breaches the Acceptable Use Policy; and (vi) not use our Software and Services to make or operate a competing virtual worlds platform."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "11. REPORTING INAPPROPRIATE OR INFRINGING CONTENT"
      })
    }), "\n", _jsx(_components.p, {
      children: "11.1. We take intellectual property infringement very seriously and comply with the provisions of the Digital Millennium Copyright Act applicable to Internet service providers (17 U.S.C. § 512, as amended) and the European Copyright Directive 2001/29/EC (as implemented). If you wish to report an alleged infringement, harassment and/or hateful, offensive or defamatory content included in material posted on the Software and Services (“Challenged Content”), you may contact our Designated Agent at the following address: Improbable MV Limited, 10 Bishops Square, London E1 6EG and/or at the following email address: legal@improbable.io."
    }), "\n", _jsx(_components.p, {
      children: "11.2. Any notice alleging that materials hosted by or distributed through the Software and Services are Challenged Content must include the following information:"
    }), "\n", _jsx(_components.p, {
      children: "11.2.1. a description of the Challenged Content and where it is located on the Software and Services;"
    }), "\n", _jsx(_components.p, {
      children: "11.2.2. if relating to an alleged infringement of intellectual property, a description of the intellectual property rights that you claim has been infringed and an explanation as to how they have been infringed;"
    }), "\n", _jsx(_components.p, {
      children: "11.2.3. your address, phone number and email address;"
    }), "\n", _jsx(_components.p, {
      children: "11.2.4. if relating to an alleged infringement of intellectual property, a statement by you that (i) you have a good-faith belief that the use of the materials on the Software and Services of which you are complaining is not authorised by the copyright or intellectual property owner, its agent, or the law, and (ii) the information that you are providing is accurate, correct, and that, under penalty of perjury, you are the copyright or intellectual property owner or authorised to act on behalf of the copyright or intellectual property owner; and"
    }), "\n", _jsx(_components.p, {
      children: "11.2.5. if relating to an alleged infringement of intellectual property, a physical or electronic signature of the person authorised to act on behalf of the owner of copyright or other right that has allegedly been infringed."
    }), "\n", _jsx(_components.p, {
      children: "11.3. M2 may take such other action as we deem appropriate, in our sole discretion, to deal with the Challenged Content, including promptly terminate the accounts of users that are determined by M2 to be infringers or are believed to be infringing the rights of copyright holders."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "12. CONFIDENTIALITY"
      })
    }), "\n", _jsx(_components.p, {
      children: "Neither party will disclose confidential information to any third party and will only release the confidential information to those of its (or, in the case of M2 only, its group companies’) directors, officers or employees who need to know it strictly for the purpose of exercising or performing that party's rights and obligations under this Agreement. Each receiving party will treat confidential information with the same degree of care and apply no lesser security measures than it affords to its own confidential information. The receiving party warrants that these measures provide adequate protection against unauthorised disclosure, copying or use. The receiving party will make no commercial use of the confidential information, except for the Purpose. Confidential information may be disclosed if and to the extent: (i) it is required by law, court order or other authority of competent jurisdiction or any regulatory or government authority to which the receiving party is subject, but in each case only to the extent required and for the purpose of such disclosure and provided that the disclosing party is promptly informed of the disclosure; (ii) the receiving party reasonably considers it necessary to disclose the information to its professional advisers, auditors or bankers provided that it does so on terms protecting the information; (iii) the information entered the public domain through no fault of the receiving party; (iv) the information was previously disclosed to the receiving party without any obligation of non-disclosure; or (v) the disclosing party has given its consent in writing."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "13. LEGAL"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["13.1. ", _jsx(_components.strong, {
        children: "Prior agreements."
      }), " This Agreement will replace any previous version of this terms of service and licence agreement but will be subject to the terms of any other written commercial agreement between the parties."]
    }), "\n", _jsxs(_components.p, {
      children: ["13.2. ", _jsx(_components.strong, {
        children: "Liability."
      }), " The maximum and total aggregate liability of M2 and its affiliates, officers, directors, employees and agents in connection with this Agreement (including but not limited to the Software and Services) will be an amount equal to the greater of: (i) $100 and (ii) any and all payments from you actually received by M2 under this Agreement. M2 will not be liable to you whether in connection with this Agreement or any collateral contract, whether caused by M2 or another third party, for any damage to property, loss of earnings, profits, charges or expenses, loss or theft of information, loss of data, loss of business, opportunity, reputational loss or harm, or any special, indirect or consequential or punitive loss or damage or disruption of any kind, in any case, whether based on breach of contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise whether or not the relevant party has been advised of the possibility of such damage. Nothing in this Agreement purports to limit or exclude any party’s liability for fraud, fraudulent misrepresentation or wilful misconduct or exclude or limit liability for death or personal injury caused by that party’s negligence or to the extent otherwise not permitted by law."]
    }), "\n", _jsxs(_components.p, {
      children: ["13.3. ", _jsx(_components.strong, {
        children: "Breach of this Agreement by you."
      }), " If you breach the terms of this Agreement and that breach causes us harm or financial loss then you agree to compensate us for all losses, harm, claims and expenses that we incur in relation to your breach. Without limitation, some examples of breaches that could cause us harm or financial loss are: (i) any unauthorised use by you of M2 Property, our confidential information or of Third Party Content; (ii) any actual or alleged claim by a third party of Intellectual Property Rights infringement or unauthorised confidential information usage in connection with the Software and Services or Third Party Content; and/or (iii) any breach by you of the Terms of Service. M2 shall have no liability to you to the extent that any claim is based upon (i) modifications to the Software and Services made by anyone other than M2; (ii) combination of the Software and Services with software not provided by M2; (iii) your failure to use modifications to the Software and Services provided by M2 to avoid infringement or misappropriation; (iv) use of the Software and Services by you which breaches M2’s terms and conditions; and (v) matters outside M2’s reasonable control."]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "14. GENERAL"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["14.1. ", _jsx(_components.strong, {
        children: "No partnership or agency."
      }), " This Agreement does not create any exclusive relationship between the parties nor any partnership, joint venture, employment or agency between them."]
    }), "\n", _jsxs(_components.p, {
      children: ["14.2. ", _jsx(_components.strong, {
        children: "No waiver."
      }), " No failure or delay by a party to exercise any right under this Agreement or at law will be a waiver of that right."]
    }), "\n", _jsxs(_components.p, {
      children: ["14.3. ", _jsx(_components.strong, {
        children: "Links."
      }), " We may link to third party websites or services from our Services. You understand that we make no promises regarding any content, products, goods or services provided by such third parties or links displayed on such websites and we do not endorse the same. We are also not responsible to you in relation to any losses or harm caused by such third parties."]
    }), "\n", _jsx(_components.p, {
      children: "You acknowledge sole responsibility for, and all risk arising from and incurred through, your use of any third-party websites or resources. This includes compliance with any and all terms of use from third-party websites or resources."
    }), "\n", _jsx(_components.p, {
      children: "You understand that when you provide data to third parties you are providing it in accordance with their privacy policy (if any) and our privacy policy does not apply in relation to that data."
    }), "\n", _jsxs(_components.p, {
      children: ["14.4. ", _jsx(_components.strong, {
        children: "Assignment."
      }), " We may transfer all or a part of our rights or responsibilities under these terms to someone else without obtaining your consent. You may not transfer any of the rights we give you under these terms unless we first agree to this in writing."]
    }), "\n", _jsxs(_components.p, {
      children: ["14.5. ", _jsx(_components.strong, {
        children: "Third parties."
      }), " Only a party to this Agreement can enforce it (whether under the UK’s Contracts (Rights of Third Parties) Act 1999 or otherwise)."]
    }), "\n", _jsxs(_components.p, {
      children: ["14.6. ", _jsx(_components.strong, {
        children: "Severability."
      }), " If any part of these terms is held to be invalid or unenforceable under any applicable local laws or by an applicable court, that part shall be interpreted in a manner consistent with applicable law to reflect as nearly as possible our original intentions and the remainder of these terms shall remain valid and enforceable. If it is not possible to interpret an invalid or unenforceable part of these terms in a manner consistent with applicable law, then that part shall be deemed deleted from these terms without affecting the remaining provisions of these terms."]
    }), "\n", _jsxs(_components.p, {
      children: ["14.7. ", _jsx(_components.strong, {
        children: "Entire agreement."
      }), " This Agreement, including our Privacy Policy and Acceptable Use Policy (set out below), constitutes the whole agreement between the parties and unless otherwise stated herein supersedes all previous agreements between them regarding its subject matter. Each party acknowledges that, in entering into this Agreement, it has not relied on, and will have no right or remedy in respect of, any statement, representation, assurance or warranty other than as expressly set out in this Agreement."]
    }), "\n", _jsxs(_components.p, {
      children: ["14.8. ", _jsx(_components.strong, {
        children: "Costs."
      }), " Each party is responsible for its own costs regarding this Agreement."]
    }), "\n", _jsxs(_components.p, {
      children: ["14.9. ", _jsx(_components.strong, {
        children: "Further assurance."
      }), " Each party will procure and will use all reasonable endeavours to procure that any necessary third party will, promptly execute and deliver such documents and perform such acts as may reasonably be required to give full effect to this Agreement."]
    }), "\n", _jsxs(_components.p, {
      children: ["14.10. ", _jsx(_components.strong, {
        children: "Governing law and jurisdiction."
      }), " This Agreement and any dispute or claim in connection with it will be governed by the law of England under the exclusive jurisdiction of the courts of England."]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "15. CONTACT"
      })
    }), "\n", _jsx(_components.p, {
      children: "If you wish to contact us in relation to this agreement, please email legal@improbable.io."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "16. PRIVACY"
      })
    }), "\n", _jsx(_components.p, {
      children: "For information regarding the collection, processing, and use of your personal information please read our Privacy Policy. If you do not agree to our Privacy Policy you should not download or access our Experiences or otherwise access and/or use our Services.M2 ACCEPTABLE USE POLICY"
    }), "\n", _jsx(_components.p, {
      children: "You agree not to, and not to allow third parties (including their end users) to, use the Software and Services to:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "probe, scan or test the vulnerability of any system or network;"
      }), "\n", _jsx(_components.li, {
        children: "breach or otherwise circumvent any security or authentication measures;"
      }), "\n", _jsx(_components.li, {
        children: "access, tamper with or use non-public areas or parts of the Software and Services, or shared areas of the Software and Services to which you do not have access rights;"
      }), "\n", _jsx(_components.li, {
        children: "generate, distribute, publish or facilitate unsolicited mass email, promotions, advertisings or other solicitations (“spam”) or false source-identifying information (“spoofing” or “fishing”);"
      }), "\n", _jsx(_components.li, {
        children: "copy, sublicence rent, lease, license, resell, distribute, reproduce, display, mirror, frame, transfer, or use the Software and Services in breach of these terms;"
      }), "\n", _jsx(_components.li, {
        children: "inundate a target with communications requests so the target either cannot respond to legitimate traffic or responds so slowly that it becomes ineffective (“DDoS”) or any other similar activities including mail bombing, news bombing, broadcast attacks, overload, spamming or flooding techniques;"
      }), "\n", _jsx(_components.li, {
        children: "modify, merge, distribute, adapt, translate, copy, reverse engineer, datamine, decompile, derive source code from, disassemble, create derivative works of, hack, disrupt, or interfere with the Software and Services or any part of them;"
      }), "\n", _jsx(_components.li, {
        children: "use IP proxying or other methods to disguise the place of your residence, whether to circumvent geographical restrictions on the Software and Services or for any other purpose;"
      }), "\n", _jsx(_components.li, {
        children: "use the Software and Services, or upload content to the M2 Servers, in any way which is detrimental to the enjoyment of the Services by other users, including: (i) is actually or allegedly illegal or infringes, misappropriate, or violates third party Intellectual Property Rights; (ii) is related to gambling, money laundering, terrorism, and/or pornographic or adult content; (iii) is defamatory, racist, obscene, violent, harassing, abusive, bullying, threatening, disrespectful, vulgar, misleading, deceptive, scamming, fraudulent, discriminatory, offensive, inappropriate or contrary to public morals or public policy; or (iv) that may be harmful to M2, its operations and/or reputation;"
      }), "\n", _jsx(_components.li, {
        children: "use, intercept, emulate, mine, redirect or otherwise collect data or information (including personal data and confidential information) from the Software and Services or other M2 systems using unauthorised third-party software or otherwise;"
      }), "\n", _jsx(_components.li, {
        children: "post, upload, transmit or facilitate the transmission of any files that contain any malicious code, including viruses, spyware, Trojan horses, worms, time bombs or intentionally corrupted data;"
      }), "\n", _jsx(_components.li, {
        children: "access the Software and Services in any manner that is intended to avoid incurring fees due under this or any other agreement;"
      }), "\n", _jsx(_components.li, {
        children: "attempt to access, copy, steal, modify or otherwise interact with Third Party Content stored or located on the M2 Servers or any areas of the Services that are not made available to the public;"
      }), "\n", _jsx(_components.li, {
        children: "upload to the M2 Servers, or the Software and Services, anything which causes damage to or affects the operation of the Software and Services or any Third Party Content;"
      }), "\n", _jsx(_components.li, {
        children: "impersonate any person, business or entity, impersonate another person or falsely imply that you are an employee or representative, including an employee of M2, misrepresent your affiliation with any person or entity, or device or mislead through messages or communicating in any way that makes it appear that the communication originates from M2 or communicating any information which is grossly offensive or menacing in nature;"
      }), "\n", _jsx(_components.li, {
        children: "impersonate any person, affiliation, entity, or publicly post identifying information about yourself, M2 employees, or other users, or collect or store any information that could be used to identify an individual, either itself or combined with other information;"
      }), "\n", _jsx(_components.li, {
        children: "harass, stalk, abuse, intimidate, intentionally offend, bully, embarrass, spam, harm or threaten other users or M2 employees or do anything else that is unwanted or offensive to another user of the Software and Services, such as repeatedly sending unwanted messages or making personal attacks or statements about race, sexual orientation, religion, heritage, or otherwise;"
      }), "\n", _jsx(_components.li, {
        children: "initiate, spread, or share hate speech or unlawful language that is harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable;"
      }), "\n", _jsx(_components.li, {
        children: "remove, disable, circumvent, modify, or alter or conceal any copyright, trademark, patent or other proprietary rights notice from the Software and Services or any technological measure implemented to protect your associated intellectual property; or"
      }), "\n", _jsx(_components.li, {
        children: "interfere with or disrupt the Software and Services, or any server or network used to support or provide any of the foregoing, including through hacking or cracking."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
